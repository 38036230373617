
export const NameTypes = {
    "Segregated": "Segregated",
    "Full": "Full"
}

export const DateFormet = {
    "DD-MM-YYYY": "DD-MM-YYYY",
    "YYYY-MM-DD": "YYYY-MM-DD"
}
export const TimeFormets = {
    "24 Hours": "24 Hours",
    "12 Hours": "12 Hours"
}
export const TermsType = {
    "With Terms & Conditions text field": "With Terms & Conditions text field",
    "Without Terms & Conditions text field": "Without Terms & Conditions text field"
}

export const SingleLineType = {
    "None": "None",
    "Alphabetic": "Alphabetic",
    "Numeric": "Numeric",
    "Alphanumeric": "Alphanumeric",
    "Email": "Email",
    "Url": "Url"
}
export const MultiLineType = {
    "Plain": "Plain Text",
    "Rich": "Rich Text",
}


export const DropdownType = {
    "None": "None",
    "States": "All States",
    "Countries": "Countries",
}


export const SingleChoiceType = {
    "None": "None",
    "Gender": "Gender",
    "Days": "Days",
    "Months": "Months",
}

export const CityDropdownType = {
    "Input": "Input Field",
    "Dropdown": "Dropdown Field",
}

export const ReCaptchaType = {
    "Google Captcha": "Google Captcha",
    "hCaptcha": "hCaptcha"
}