/** ==================================================== Configrations =============================================================== */
// Old Servers
// export const API_URI = 'https://www.app.turnintern.com'; //for dev
// export const API_URI = 'https://www.app.ecollat.com'; //for production

export const API_URI = process.env.REACT_APP_API_URI; //for dev by chanden sir
export const ASSETS_URI = process.env.REACT_APP_ASSETS_URI; //for dev by chanden sir Images
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URI
export const Environment = process.env.REACT_APP_ENVIRONMENT === 'false' ? false : false


export const CMS_API_URL = process.env.REACT_APP_CMS_API_URL
export const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;
export const FORM_API_URI = process.env.REACT_APP_FORM_API_URL;




export const FORM_DOMAIN_URL = process.env.REACT_APP_FORM_DOMAIN;


export const YOUR_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLEMAP_KEY;

/** ========================================================== END =================================================================== */

/** ======================================================= Constants ================================================================= */
export const CACHE_NAME = 'productCache';

export const ANALYTICS_VISITORS = 'analytics/gettotalvisitor';
export const ANALYTICS_VIEWS = 'clientpdfviews';
export const ANALYTICS_WORDS = 'singlewordanalystics';

export const actions = [
  {
    slug: 'pageLink',
    title: 'Page Link',
    image: '/manage/images/SVG/note_add.svg',
  },
  {
    slug: 'link',
    title: 'Link',
    image: '/manage/images/SVG/link.svg',
  },
  {
    slug: 'video',
    title: 'YouTube Video',
    image: '/manage/images/SVG/video_library.svg',
  },
  // {
  //   slug: 'iframe',
  //   title: 'Iframe Link',
  //   image: '/manage/images/SVG/filter_frames.svg',
  // },
  {
    slug: 'text',
    title: 'Custom Text',
    image: '/manage/images/SVG/text_fields.svg',
  },
  {
    slug: 'addProduct',
    title: 'Add Product',
    image: '/manage/images/dashboard-icons/Text.png',
  },
];

export const actionsv2 = [
  {
    slug: 'pageLink',
    title: 'Page Link',
    image: '/manage/images/options-v2/Page-link.svg',
  },
  {
    slug: 'link',
    title: 'Link',
    image: '/manage/images/options-v2/Link.svg',
  },
  {
    slug: 'video',
    title: 'YouTube Video',
    image: '/manage/images/options-v2/Video.svg',
  },
  {
    slug: 'iframe',
    title: 'Iframe Link',
    image: '/manage/images/options-v2/iframe.svg',
  },
  {
    slug: 'text',
    title: 'Custom Text',
    image: '/manage/images/options-v2/Text.svg',
  },
  {
    slug: 'addProductDetails',
    title: 'Add Product',
    image: '/manage/images/options-v2/addproduct.svg',
  },
  {
    slug: 'addProduct',
    title: 'Add Product',
    image: '/manage/images/options-v2/Text.png',
  },
  // {
  //   slug: 'carousel',
  //   title: 'Add Carousel',
  //   image: '/manage/images/options-v2/Carousal.svg',
  // },
];

export const BTN_ICONS = [
  {
    path: '/manage/images/btnIcons/btnIcon1.svg',
    id: '1',
    name: 'External Link',
  },
  {
    path: '/manage/images/btnIcons/btnIcon2.svg',
    id: '2',
    name: 'Play',
  },
  {
    path: '/manage/images/btnIcons/btnIcon3.svg',
    id: '3',
    name: 'Add-1',
  },
  {
    path: '/manage/images/btnIconsnIcon4.svg',
    id: '4',
    name: 'Add-2',
  },
  {
    path: '/manage/images/btnIcons/btnIcon5.svg',
    id: '5',
    name: 'Add-3',
  },
];

export const userRoles = [
  {
    label: 'admin',
    value: '2',
  },
  {
    label: 'user',
    value: '3',
  },
];

/** ========================================================== END =================================================================== */
