/* eslint-disable import/no-anonymous-default-export */
import { CMS_DELETE_NAVIGATIONS, CMS_DOMAINS, CMS_GET_NAVIGATIONS, ERROR_MEDIA_LIBRARY_CONTENT, LOADER_MEDIA_LIBRARY_CONTENT, SET_CONTENT_TYPE_COLLECTION, SET_CONTENT_TYPE_COMPONENTS, SET_CONTENT_TYPE_ERROR, SET_CONTENT_TYPE_LOADING, SET_CONTENT_TYPE_SINGLE, SET_CONTENT_TYPE_SUCCESS, SET_MEDIA_LIBRARY_CONTENT } from '../actions/types';

const initialState = {
    collections: [],
    components: [],
    singles: [],
    media: [],
    domains: [],
    navigations: [],
    error: false,
    loading: true,
    loadingmedia: false,
    errormedia: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTENT_TYPE_COLLECTION:
            return {
                ...state,
                collections: action.payload,
                loading: false,
                error: false,
            };
        case SET_MEDIA_LIBRARY_CONTENT:
            return {
                ...state,
                media: action.payload,
                errormedia: false,
                loading: false,

            };
        case SET_CONTENT_TYPE_SINGLE:
            return {
                ...state,
                singles: action.payload,
                error: false,
            };
        case SET_CONTENT_TYPE_COMPONENTS:
            return {
                ...state,
                components: action.payload,
                error: false,
            };
        case SET_CONTENT_TYPE_ERROR:
            return {
                ...state,
                error: true,
            };
        case SET_CONTENT_TYPE_LOADING:
            return {
                ...state,
                error: false,
                loading: true
            };
        case SET_CONTENT_TYPE_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false
            };
        case ERROR_MEDIA_LIBRARY_CONTENT:
            return {
                ...state,
                errormedia: true,
            };
        case LOADER_MEDIA_LIBRARY_CONTENT:
            return {
                ...state,
                loadingmedia: true,
            };

        case CMS_DOMAINS:
            return {
                ...state,
                domains: action.payload
            }
        case CMS_GET_NAVIGATIONS:
            return {
                ...state,
                navigations: action.payload
            }
        case CMS_DELETE_NAVIGATIONS:
            return {
                ...state,
                navigations: [
                    ...state.navigations.slice(0, action.payload),
                    ...state.navigations.slice(action.payload + 1) 
                ]
            };
        default:
            return state;
    }
};
